@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  display: grid;
  height: 100vh;
  grid-template-rows: min-content 1fr min-content;
  justify-content: center;
  width: 100vw;
}

.header {
  padding: 1rem;
  /* background-color: #2E4C6D; */
  /* color: #DADDFC; */
  display: grid;
  justify-content: center;
  width: 100%;
  /* grid-template-columns: min-content 1fr; */
}

.body {
  padding: 1rem;
  width: 100vw;
  display: grid;
  justify-content: center;
}

.footer {
  padding: 2rem;
  overflow-wrap: anywhere;
  display: grid;
  justify-content: center;
}

.text-end .ant-form-item-control {
  text-align: end;
}

.name-generate {
  display: flex;
  align-items: center;
}

.name-generate button {
  margin-top: -23px;
  margin-left: 10px;
}